import Button from "components/Button";
import calcFinanzamtLetters from "components/PDFReader/calcFinanzamtLetters";
// import PricingTable from "components/PricingTable/PricingTable";
import { useUserData } from "data-layer/userData";
import { useUserInteraction } from "data-layer/userInteraction";
import { useUserTesting } from "data-layer/userTesting";
import React, { useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { sendAmplitudeData } from "res/amplitude";
import tax_offices from "res/FormData/de/tax_office.json";
import eagle from "res/images/eagle.png";
import { isDev, isStaging } from "settings/config";
import { useLocalFormVal } from "../../../hooks/useLocalState";
import Field from "../../Field";
import Fieldset from "../Fieldset";
import FormHeader from "../FormHeader";
import {
  getFirstVATDeadline,
  getFirstZMDeadline,
} from "../helper-functions/taxDeadlines";
import { previewForm, sendForm } from "../sendData";
import ErrorMessage from "./ErrorMessage";
import PreviewLink from "./PreviewLink";

function handleVersionQueryString(searchQuery, prop) {
  const urlParams = searchQuery.replace(/^\?/, "");
  return urlParams;
}

const Review = ({ currentStep, nextStep }) => {
  const { search } = useLocation();
  const paidUser = handleVersionQueryString(search, "paidonraso");
  const { userInteraction, setUserInteraction } = useUserInteraction();
  const { userTesting } = useUserTesting();
  const { register, handleSubmit, watch, errors, reset, getValues, formState } =
    useForm({
      mode: userInteraction.stepsCompleted.includes("reviewFields")
        ? "onChange"
        : "onBlur",
    });
  const onSubmit = (data) => nextStep(data, "reviewFields", false);
  const { t, i18n } = useTranslation();
  const { userData, setUserData } = useUserData();
  const linkRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const { previewLink, success, code = "" } = userInteraction;
  const taxOffice = watch("taxOffice");

  useEffect(() => {
    if (
      formState.isDirty &&
      !userInteraction.touchedScreens.includes(currentStep.tabId)
    ) {
      setUserInteraction({
        startedFilling: true,
        touchedScreens: [...userInteraction.touchedScreens, "reviewFields"],
      });
    }
  }, [formState.isDirty, setUserInteraction]);

  useEffect(() => {
    setUserData(
      {
        finanzamtLetters: calcFinanzamtLetters(userData),
      },
      "reviewFields",
      true
    );
  }, []);

  const localFormVals = getValues();
  useLocalFormVal({
    key: "reviewFields",
    reset,
    localFormVals,
    errors,
  });
  useEffect(() => {
    if (linkRef?.current && previewLink) {
      linkRef.current.focus();
    }
  }, [linkRef, previewLink]);

  useEffect(() => {
    if (userInteraction?.data?.data?.messages?.errors) {
      const submissionErrors = {};
      sendAmplitudeData("RASO_SUBMISSION_ERROR", submissionErrors);
    }
  }, [userInteraction?.data?.data?.messages?.errors]);

  return (
    <>
      <form id={currentStep.tabId} onSubmit={handleSubmit(onSubmit)}>
        <Fieldset section>
          <div className="form">
            <FormHeader currentStep={currentStep} />
            <Fieldset title={t("review_proof_read_fieldset_title")}>
              <Field
                type="select"
                topLabel={t("taxOffice_label")}
                name="taxOffice"
                ref={register({
                  required: true,
                  validate: (value) =>
                    !/choose/.test(value) || t("field_invalid"),
                })}
                errors={errors}
                watch={watch}
                options={tax_offices}
                fieldHelperText={t("taxOffice_helper")}
                secondFieldHelperText={t("taxOffice_helper_2")}
              />
            </Fieldset>
          </div>
          <div className="form__review--actions">
            {!success && (
              <div className="tab-helper">
                {userInteraction.stepsCompleted.length < 5 ? (
                  <p className="tab-helper__heading">
                    {t("review_please_complete", {
                      interpolation: { escapeValue: false },
                    })}
                  </p>
                ) : (
                  <p className="tab-helper__heading">
                    {t("review_proof_read", {
                      interpolation: { escapeValue: false },
                    })}
                  </p>
                )}
                {(isDev || isStaging) &&
                  !/accountable/gi.test(userData.personalFields.email) ? (
                  <p style={{ color: "var(--color-invalid_red)" }}>
                    Please use an @accountable.eu address for testing
                  </p>
                ) : null}

                {/* PREVIEW BUTTON */}
                <Button
                  onClick
                  func={async () => {
                    if (userInteraction.stepsCompleted.length < 5) {
                      return;
                    }
                    setLoading(true);
                    const { firstZMDeadline, ZMPayingRhythm } =
                      getFirstZMDeadline(userData);
                    const { firstVATDeadline, vatPayingRhythm } =
                      getFirstVATDeadline(userData);
                    const ar = await previewForm({
                      fields: {
                        ...userData,
                        reviewFields: { ...userData.reviewFields, taxOffice },
                      },
                      preview: true,
                      sLang: i18n.language,
                      sPartner: "",
                      successPageVersion: userTesting.successPage,
                    });
                    setUserData(
                      {
                        firstZMDeadline_at: firstZMDeadline,
                        ZMPayingRhythm: ZMPayingRhythm,
                        vatPayingRhythm: vatPayingRhythm,
                        firstVATDeadline_at: firstVATDeadline,
                      },
                      "taxInfoFields",
                      true
                    );
                    setUserData(
                      {
                        taxOffice: taxOffice,
                      },
                      "reviewFields",
                      true
                    );
                    setUserInteraction({ ...ar, preview: true });
                    setLoading(false);
                  }}
                  disabled={
                    userInteraction.stepsCompleted.length < 5 ||
                    /choose/.test(taxOffice)
                  }
                  text={t("review_button_gen_pdf")}
                  className="body--big-bold"
                  type="submit"
                  isLoading={loading}
                />
              </div>
            )}
            {previewLink && <PreviewLink linkRef={linkRef} previewLink={previewLink} />}

            {success && (
              <div className="tab-helper form_submit--review">
                {paidUser === "paidonraso" ? (
                  ""
                ) : (
                  <>
                    <p className="body--small">
                      {t("review_button_success_heading")}
                    </p>
                  </>
                )}
                {/* SEND FORM */}
                <div className="form_submit--buttonContainer">
                  <Button
                    func={async () => {
                      setLoading(true);
                      const ar = await sendForm({
                        fields: userData,
                        preview: true,
                        sLang: i18n.language,
                        sPartner: "",
                        successPageVersion: userTesting.successPage,
                      });

                      setLoading(false);
                      if (ar.data.success) {
                        nextStep(userData.reviewFields, "reviewFields", true, {
                          ...ar,
                          send: true,
                        });
                      } else {
                        setUserInteraction(ar);
                      }
                    }}
                    isLoading={loading}
                    className="body--big-bold notranslate"
                    type="button"
                  >
                    <img
                      src={eagle}
                      className={"button--img"}
                      alt={t("review_button_send_pdf")}
                    />
                    {t("review_button_send_pdf")}
                  </Button>
                </div>
              </div>
            )}
            {paidUser !== "paidonraso" && (
              <p className="body--small">{t("disclaimer")}</p>
            )}
            {ErrorMessage(code, success)}
          </div>
        </Fieldset>
      </form>
    </>
  );
};
export default Review;
